<template>
  <ion-page>
    <ion-header>
      <ion-toolbar class="toolbar-header">
        <div class="d-flex align-center justify-space-between">
          <ion-label class="fw-600 fs-3">{{ $t('payment_summary') }}</ion-label>
        </div>
      </ion-toolbar>
    </ion-header>
    <ion-content :scroll-events="true">
      <div class="w-100 bg-gray-modal h-100 pt-5 pb-5">
        <div class="pb-5 d-flex align-center justify-center flex-column">
          <ion-img :src="paymentStatusVariant?.icon" class="status-icon-width" />
          <ion-label class="fs-4 fw-600 mt-2 mb-2" :class="paymentStatusVariant?.color">
            {{ paymentStatusVariant?.label }}
          </ion-label>
        </div>
        <ion-grid class="my-2 box px-2 py-2">
          <ion-row class="mb-1 mx-1 ion-justify-content-between">
            <ion-label class="text-gray-700">{{ `Invoice(s) Amount` }}</ion-label>
            <ion-label style="float: right" class="fw-600 text-gray-700">{{ invoiceAmountValue }}</ion-label>
          </ion-row>
          <ion-row class="mb-1" v-for="(invoice, i) in invoicesData" :key="i">
            <ion-col push="0.3">
              <ion-label class="text-gray-700">{{ invoice.label }}</ion-label>
            </ion-col>
            <ion-col size="auto">
              <ion-label class="fw-600 text-gray-700">{{ invoice.value }}</ion-label>
            </ion-col>
          </ion-row>
          <ion-row
            v-for="(item, idx) in dataSummary"
            :key="idx"
            class="mb-1"
            :class="{ 'border-bottom': [1, 2, 4].includes(idx) }"
          >
            <ion-col>
              <ion-label
                class="text-gray-700"
                :class="{
                  'text-primary-green-600': idx === 0,
                  'fw-700': idx == 5,
                  'fw-500': [1, 2, 0].includes(idx)
                }"
                >{{ item.label }}</ion-label
              >
            </ion-col>
            <ion-col size="auto">
              <ion-text
                class="fw-600 text-gray-700"
                :class="
                  idx === 5 ? `${paymentStatusVariant?.color}` : idx === 0 ? 'text-primary-green-600' : ''
                "
              >
                {{ item?.value }}</ion-text
              >
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col> </ion-col>
            <ion-col size="auto">
              <ion-text class="fw-700 text-gray-700">
                {{ priceFormatter(currencySymbol, totalPaidAmount) }}</ion-text
              >
            </ion-col>
          </ion-row>
          <ion-button class="my-2" @click="$emit('backToInvoice')" expand="block" color="primary">
            <ion-label class="text-capitalize">
              {{ $t('back_to_invoice') }}
            </ion-label>
          </ion-button>
          <ion-button class="mb-4" @click="$emit('backToHome')" fill="outline" expand="block">
            <ion-label class="text-capitalize">
              {{ $t('back_to_home') }}
            </ion-label>
          </ion-button>
        </ion-grid>
      </div>
    </ion-content>
  </ion-page>
</template>
<script>
import { PAYMENT_STATUS_ICONS, STRIPE_PAYMENT_STATUS } from '@/modules/b2b/constants';
import { DIGITAL_PAYMENT_TYPE_LABEL, INVOICE_PAYMENT_TYPE } from '@/modules/shared/constants/';
import { useDateFormatter } from '@/usecases/global';
import { priceFormatter } from '@/utils/';
import { useBackButton } from '@ionic/vue';
import { closeCircleOutline, timerOutline } from 'ionicons/icons';
import { defineComponent, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
export default defineComponent({
  name: 'ModalDigitalPaymentSummary',
  emits: ['backToInvoice', 'backToHome', 'cancelPaynowPayment', 'viewQrCode', 'closeModal'],
  props: {
    paymentSummary: {
      type: Object,
      default: () => {}
    },
    currencySymbol: {
      type: String,
      default: 'S$'
    },
    invoices: {
      type: Array,
      default: () => []
    },
    appliedCredit: {
      type: Number,
      default: 0
    },
    totalPaidAmount: {
      type: Number,
      default: 0
    },
    grandTotal: {
      type: Number,
      default: 0
    },
    paymentType: {
      type: Number,
      default: INVOICE_PAYMENT_TYPE.CREDIT_CARD
    }
  },
  setup(props) {
    const { t } = useI18n();
    const router = useRouter();
    const { formatDateMonthYear, isValidDate } = useDateFormatter();
    const paymentStatusVariant = ref({
      label: t('payment_success'),
      icon: PAYMENT_STATUS_ICONS.SUCCESSFUL,
      color: 'text-primary-green-600',
      btmLabel: t('paid').toUpperCase(),
      value: STRIPE_PAYMENT_STATUS.APPROVED
    });

    const paymentMethod = ref(DIGITAL_PAYMENT_TYPE_LABEL[props.paymentType]);
    const invoicesData = props.invoices.map((invoice) => {
      return {
        label: invoice.invoiceNumber,
        value: priceFormatter(props.currencySymbol, invoice.outstandingAmount)
      };
    });
    const invoiceAmount = props.invoices.reduce((acc, invoice) => {
      const sum = acc + invoice.outstandingAmount ?? 0;
      return sum;
    }, 0);
    const invoiceAmountValue = priceFormatter(props.currencySymbol, invoiceAmount);

    const remainingTime = ref(null);

    const formatPrice = (value) => priceFormatter(props.currencySymbol, value);
    const transactionDate = isValidDate(props.paymentSummary?.details?.batchPayment?.created_at)
      ? formatDateMonthYear(new Date(props.paymentSummary?.details?.batchPayment?.created_at))
      : `-`;
    const dataSummary = ref([
      {
        label: t('applied_credits'),
        value: formatPrice(props.appliedCredit)
      },
      {
        label: t('sub_total'),
        value: formatPrice(props.grandTotal)
      },
      {
        label: t('total_payment'),
        value: formatPrice(props.totalPaidAmount)
      },
      {
        label: t('payment_method'),
        value: paymentMethod.value ?? 'Debit'
      },
      {
        label: t('transaction_date'),
        value: transactionDate
      },
      {
        label: t('total'),
        value: paymentStatusVariant?.value?.btmLabel || '-'
      }
    ]);
    useBackButton(10, () => {
      router.push('/b2b/main/home');
    });

    return {
      invoicesData,
      invoiceAmountValue,
      dataSummary,
      paymentStatusVariant,
      timerOutline,
      priceFormatter,
      closeCircleOutline,
      remainingTime
    };
  }
});
</script>
<style lang="scss" scoped>
.status-icon-width {
  width: 80px;
}
.f-icon {
  font-size: 3rem;
}
ion-content {
  --ion-background-color: #e5e5e5;
}

ion-button {
  width: 97%;
  margin: 0 auto;
  --border-radius: 10px;
}
.box {
  border: 1px solid var(--ion-color-text-gray-300);
  background-color: #fff;
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.2);
  border-radius: 10px !important;
  width: 94%;
  margin: 0 auto;
}
</style>
