<template>
  <div class="container">
    <ion-grid>
      <ion-row>
        <ion-label class="label mb-2"> {{ $t('summary') }} </ion-label>
      </ion-row>
      <ion-row class="mb-2">
        <ion-col>
          <ion-label class="fw-600 text-gray-700">{{ $t('invoice_list') }}</ion-label>
        </ion-col>
        <ion-col size="auto">
          <ion-text class="fw-600 text-gray-700"> {{ priceLabelFormatter(totalInvoicesAmount) }} </ion-text>
        </ion-col>
      </ion-row>
      <ion-row v-for="invoice in selectedInvoices" :key="invoice.amount">
        <ion-col>
          <ion-label class="pl-2 text-gray-700">{{ invoice.invoiceNumber }}</ion-label>
        </ion-col>
        <ion-col size="auto">
          <ion-label class="fw-600 text-gray-700">{{
            priceLabelFormatter(invoice.outstandingAmount)
          }}</ion-label>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <ion-text class="text green fw-600">{{ $t('applied_credits') }} </ion-text>
        </ion-col>
        <ion-col size="auto">
          <ion-text class="text fw-600 text-gray-700">
            {{ priceLabelFormatter(customerCreditAllocation) }}</ion-text
          >
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <ion-text class="fw-600 text-gray-700">{{ $t('sub_total') }} </ion-text>
        </ion-col>
        <ion-col size="auto">
          <ion-text class="text fw-600 text-gray-700"> {{ priceLabelFormatter(grandTotal) }}</ion-text>
        </ion-col>
      </ion-row>
      <div class="line"></div>
      <ion-row>
        <ion-col class="grand-total">
          <ion-text class="text text-uppercase top-line fw-600 text-gray-700"
            >{{ $t('total_payment') }}
          </ion-text>
        </ion-col>
        <ion-col size="auto">
          <ion-text class="text fw-600 text-gray-700">
            {{ priceLabelFormatter(totalPaidAmountData) }}
          </ion-text>
        </ion-col>
      </ion-row>
      <ion-row v-if="overdueAmount > 0">
        <ion-col>
          <ion-text>{{ $t('amount_due') }} </ion-text>
        </ion-col>
        <ion-col size="auto">
          <ion-text class="text fw-600 text-gray-700"> {{ priceLabelFormatter(overdueAmount) }}</ion-text>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</template>
<script>
import { priceFormatter } from '@/utils/';
import { defineComponent, ref } from 'vue';
export default defineComponent({
  name: 'CompSummaryInfo',
  props: {
    selectedInvoices: {
      type: Array,
      default: () => []
    },
    customerCreditAllocation: {
      type: Number,
      default: 0
    },
    currencySymbol: {
      type: String,
      default: ''
    },
    totalInvoicesAmount: {
      type: Number,
      default: 0
    },
    grandTotal: {
      type: Number,
      default: 0
    },
    totalPaidAmount: {
      type: Number,
      default: 0
    }
  },
  setup(props) {
    return {
      priceFormatter,
      totalPaidAmountData: ref(props.totalPaidAmount),
      overdueAmount: ref(0),
      overpaymentAmount: ref(0)
    };
  },
  watch: {
    totalPaidAmount(value) {
      this.calculateOnTotalPaidChange(value);
    },
    customerCreditAllocation(value) {
      this.calculateOnCreditAllocationChange(value);
    }
  },
  methods: {
    priceLabelFormatter(value) {
      return priceFormatter(this.currencySymbol, value);
    },
    calculateOnTotalPaidChange(totalPaid) {
      this.totalPaidAmountData = totalPaid;
      const substring = (totalPaid - Number(this.grandTotal)).toFixed(2);
      if (+substring >= 0) {
        this.overdueAmount = 0;
        this.overpaymentAmount = substring;
      }
      if (substring < 0) {
        this.overpaymentAmount = 0;
        this.overdueAmount = Math.abs(substring);
      }
    },
    calculateOnCreditAllocationChange(customerCredit) {
      const substring = (this.totalPaidAmount - Number(this.totalInvoice) + customerCredit).toFixed(2);
      if (substring > 0) {
        this.overdueAmount = 0;
        this.overpaymentAmount = substring;
      }
      if (substring <= 0) {
        this.overpaymentAmount = 0;
        this.overdueAmount = Math.abs(substring);
      }
    }
  }
});
</script>
<style lang="scss" scoped>
.container {
  width: 100%;
}
.line {
  width: 100%;
  margin: 0.3rem 0;
  height: 2px;
  border-top: 1px solid rgb(201, 201, 201);
}
.green {
  color: #039855;
}
.label {
  padding-bottom: 0.7rem;
  padding-left: 0.5rem;
  width: 100%;
  font-weight: bold;
  font-size: 13px;
  color: #757575;
  text-transform: uppercase;
  border-bottom: 1px solid rgb(201, 201, 201);
}
</style>
