<template>
  <ion-footer>
    <ion-toolbar class="mx-0">
      <ion-grid>
        <ion-row class="ion-align-items-center">
          <ion-col>
            <ion-row>
              <ion-text>{{ totalPayment }}</ion-text>
            </ion-row>
            <ion-row>
              <ion-label class="f-price">{{ priceFormatter(currencySymbol, totalPaidAmountData) }}</ion-label>
            </ion-row>
          </ion-col>
        </ion-row>
        <div class="d-flex align-center justify-center mt-5">
          <ion-button
            color="primary"
            fill="solid"
            @click="$emit('handlePayInvoices')"
            :disabled="!paymentType || (paymentType === 7 && !isFullyPaidCustBalance)"
          >
            <ion-icon
              v-show="isDigitalPayment || isFullyPaidCustBalance"
              color="light"
              :icon="shieldCheckmarkOutline"
            ></ion-icon>
            <ion-label class="ml-1">{{
              isDigitalPayment || isFullyPaidCustBalance ? $t('pay') : $t('next')
            }}</ion-label>
          </ion-button>
        </div>
      </ion-grid>
    </ion-toolbar>
  </ion-footer>
</template>
<script>
import { priceFormatter } from '@/utils/';
import { pencilOutline, shieldCheckmarkOutline } from 'ionicons/icons';
import { defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';
export default defineComponent({
  name: 'CompBottomContent',
  emits: ['handlePayInvoices'],
  props: {
    totalPaidAmount: {
      type: Number,
      default: 0
    },
    currencySymbol: {
      type: String,
      default: ''
    },
    isDigitalPayment: {
      type: Boolean,
      default: false
    },
    paymentType: {
      type: Number,
      default: 0
    },
    isFullyPaidCustBalance: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const { t } = useI18n();
    const totalPayment = t('total_payment');
    return {
      totalPayment,
      shieldCheckmarkOutline,
      pencilOutline,
      priceFormatter
    };
  },
  data() {
    return {
      totalPaidAmountData: this.totalPaidAmount
    };
  },
  watch: {
    totalPaidAmount(value) {
      this.totalPaidAmountData = value;
    }
  }
});
</script>
<style lang="scss" scoped>
ion-button {
  --border-radius: 10px;
  width: 95%;
  height: 44px;
}
ion-label {
  text-transform: capitalize;
}
.f-price {
  font-weight: bold;
  font-size: 22px;
  margin-top: 0.7rem;
}
.i-pencil {
  font-size: 1rem;
}
ion-card {
  margin-bottom: 1px;
  border: none;
}
</style>
